/*
|--------------------------------------------------------------------------
| Users management features and utilities composable
|--------------------------------------------------------------------------
|
| This composable JS file contains all common properties and methods
| used in the different views or components for the Users management
| of the application.
*/

// Import Vue.js Framework functions
import { reactive } from 'vue'

// import own utility function library
import useCrudComp from './useCrudComp.js'
import useErrorsComp from '@/composables/useErrorsComp.js'
import useDateTimeUtilsComp from '@/composables/useDateTimeUtilsComp.js'
import useObjectsUtils from './useObjectsUtils.js'

export default function useMealsComp() {

    // define variable with the needed utils functions
    const useCrud = useCrudComp()
    const useErrors = useErrorsComp()
    const useDateTimeUtils = useDateTimeUtilsComp()
    const objUtils = useObjectsUtils()

    // define the reactive variables
    const state = reactive({
        form: {
            body: {
                id: null,
                customer_id: null,
                group_id: null,
                date: '',
                city: '',
                quantity: '',
                comment: '',
            },
            options: {
                repetition: [
                    { text: 'Unique event', value: 0 },
                    { text: 'Every day', value: 1 },
                    { text: 'Every week', value: 2 },
                    { text: 'Every month', value: 3 },
                    //{ text: 'Every year', value: 4 },
                ],
                endRepetition: [
                    { text: 'Number of occurrences', value: 0 },
                    { text: 'Until given date', value: 1 },
                ],
            },
            recuring: false,
            repetition: 0,
            endRepetition: 0,
            occurrences: 0,
            endDate: null,
            addTimeFunction: null,
            status: null,
            resources: null,
            loading: true,
            response: {
                message: null,
                alert: null,
            }
        },
    })

    // Flush all user's attributes
    function flushAttributes() {
        state.form.body.id = null
        state.form.body.customer_id = null
        state.form.body.group_id = null
        state.form.body.date = ''
        state.form.body.city = ''
        state.form.body.quantity = ''
        state.form.body.comment = ''
        state.form.recuring = false

        state.form.occurrences = 0
        state.form.repetition = 0
        state.form.endDate = ''
        state.form.endRepetition = 0
    }


    // Get resources from backend
    function getMealsFromDateInterval(interval) {

        state.form.loading = true

        return useCrud.getResourcesFromDateInterval({
            url: 'meals/fromInterval',
            resource: interval
        })
        .then((res) => {
            state.form.resources = res.data.data
            state.form.loading = false
            state.form.status = 'success'
        });
    }


    function storeUnique() {
        let meals = []
        let obj = objUtils.deepCloneObject(state.form.body)
        meals.push(obj)
        return meals
    }


    function storeWithEndDate() {

        loadAddTimeFunction()

        let meals = []
        let date = state.form.body.date  // given date from formular
        let endDate = state.form. endDate  // given end date from formular
        let i = 0

        while (date <= endDate) {

            // clone complete object
            let obj = objUtils.deepCloneObject(state.form.body)

            // increment date to next occurrence
            obj.date = state.form.addTimeFunction(new Date(state.form.body.date), i)
            meals.push(obj)

            // increment to next time bloc
            i++
            date = state.form.addTimeFunction(new Date(state.form.body.date), i)
        }

        return meals
    }


    function loadAddTimeFunction() {

        switch (state.form.repetition) {
            case 1:
                state.form.addTimeFunction = useDateTimeUtils.addDays
                break;
            case 2:
                state.form.addTimeFunction = useDateTimeUtils.addWeeks
                break;
            case 3:
            case 4:
                state.form.addTimeFunction = useDateTimeUtils.addMonths
                break;
            default:
                state.form.addTimeFunction = useDateTimeUtils.addDays
        }
    }


    function storeWithOccurrences() {

        loadAddTimeFunction()

        let meals = []

        // fill array with all objects
        for (var i = 0; i < state.form.occurrences; i++) {

            let obj = objUtils.deepCloneObject(state.form.body)

            obj.date = state.form.addTimeFunction(new Date(state.form.body.date), i)

            meals.push(obj)
        }

        return meals
    }



    // Store new created resource to backend
    function storeResource(interval) {

        useErrors.useResetErrors()
        state.form.status = 'saving'

        // define new array to contain all occurrences
        let meals = []

        // If repeating event
        if (state.form.repetition !== 0) {
            if (state.form.endRepetition === 0) {
                meals = storeWithOccurrences()
            } else {
                meals = storeWithEndDate()
            }
        } else {
            // If unique event
            meals = storeUnique(state.form.body)
        }

        return useCrud.storeResource({
            url: 'meals',
            resource: meals,
        })
        .then((res) => {

            //console.log(res)

            let newResources = res.data.resources.data
            //let newResources = meals

            for (var i = 0; i < newResources.length; i++) {
                // Add only elements to show on interface
                if (newResources[i].date >= interval.start && newResources[i].date <= interval.end) {
                    let index = state.form.resources.findIndex(x => x.id === newResources[i].id)

                    // if resource not existing in array
                    if (index < 0) {
                        // add resource to array
                        state.form.resources.push(newResources[i])
                    } else {
                        // update resource in array
                        state.form.resources.splice(index, 1, newResources[i])
                    }
                }
            }

            state.form.status = 'success'
        })
    }

    // Update modified resource in backend
    function updateResource() {

        useErrors.useResetErrors()
        state.form.status = 'updating'

        let id = state.form.body.id

        return useCrud.updateResource({
            url: 'meals/' + id,
            resource: state.form.body
        })
        .then((res) => {
            var index = state.form.resources.findIndex(x => x.id === res.data.resource.id)

            // if resource not existing in array
            if (index < 0) {
                // add resource to array
                state.form.resources.push(res.data.resource)
            } else {
                // update resource in array
                state.form.resources.splice(index, 1, res.data.resource)
            }

            state.form.status = 'success'
            state.form.response.message = res.data.message
        })
    }

    // Delete resource in backend
    function deleteResource(id) {

        return useCrud.deleteResource({
            url: 'meals/' + id,
            id: id
        })
        .then(() => {
            var index = state.form.resources.findIndex(x => x.id === id)
            state.form.resources.splice(index, 1)

            state.form.status = 'success'
        })

    }

    function deleteResourcesFromGroupId(resource) {

        return useCrud.deleteResourcesFromGroupId({
            url: 'meals/fromGroupId/' + resource.id,
            id: resource.id
        })
        .then(() => {

            // Remove all elements which had same groupId
            state.form.resources = state.form.resources.filter(x => x.group_id !== resource.group_id);

            state.form.status = 'success'
        })
    }




    return {
        state,
        flushAttributes,
        useErrors,
        getMealsFromDateInterval,
        storeResource,
        updateResource,
        deleteResource,
        deleteResourcesFromGroupId
    }



}
