<template>

    <div class="container">

        <div class="row my-2">
            <div class="col">
                <button
                    class="btn btn-primary me-2"
                    @click.prevent="createResource()"
                >
                    <i class="bi bi-plus-lg"></i>
                </button>
                <button
                    class="btn btn-secondary me-2"
                    @click.prevent="previousWeek()"
                >
                    <i class="bi bi-chevron-left"></i>
                </button>
                <button
                    class="btn btn-secondary me-2"
                    @click.prevent="nextWeek()"
                >
                    <i class="bi bi-chevron-right"></i>
                </button>
                <button
                    class="btn btn-info me-2"
                    @click.prevent="setToday()"
                >
                    Today
                </button>

                <span class="me-3">
                    Calendar week : {{ useDateTimeUtils.getWeekNumber(useDateTimeUtils.getMonday(state.date)) }}
                </span>
                <span class="me-3">
                    From {{ showDate(useDateTimeUtils.getMonday(state.date)) }} to {{ showDate(useDateTimeUtils.getSunday(state.date)) }}
                </span>
            </div>
            <div class="col">
                <Datepicker
                    v-model="state.date"
                    :previewFormat="format"
                    @update:modelValue="updateDate"
                    :enableTimePicker="false"
                    locale="fr"
                    name="date-picker"

                ></Datepicker>
            </div>
        </div>



        <div class="row bg-secondary text-light  text-center">
            <div class="col">
                <p class="mt-2">
                    Monday
                    <br>
                    {{ showDate(useDateTimeUtils.getMonday(state.date)) }}
                </p>
            </div>
            <div class="col">
                <p class="mt-2">
                    Tuesday
                    <br>
                    {{ showDate(useDateTimeUtils.getTuesday(state.date)) }}
                </p>
            </div>
            <div class="col">
                <p class="mt-2">
                    Wednesday
                    <br>
                    {{ showDate(useDateTimeUtils.getWednesday(state.date)) }}
                </p>
            </div>
            <div class="col">
                <p class="mt-2">
                    Thursday
                    <br>
                    {{ showDate(useDateTimeUtils.getThursday(state.date)) }}
                </p>
            </div>
            <div class="col">
                <p class="mt-2">
                    Friday
                    <br>
                    {{ showDate(useDateTimeUtils.getFriday(state.date)) }}
                </p>
            </div>
            <div class="col">
                <p class="mt-2">
                    Saturday
                    <br>
                    {{ showDate(useDateTimeUtils.getSaturday(state.date)) }}
                </p>
            </div>
            <div class="col">
                <p class="mt-2">
                    Sunday
                    <br>
                    {{ showDate(useDateTimeUtils.getSunday(state.date)) }}
                </p>
            </div>
        </div>


        <div class="row">
            <div
                class="col"
                v-for="n in 7"
                v-bind:key="n"
            >
                <template
                    v-for="meal in useMeals.state.form.resources"
                    v-bind:key="meal"
                >

                    <div
                        v-if="useDateTimeUtils.checkWeekDay(meal.date, n)"
                        class="card mt-3"
                    >

                        <h5 class="card-header fs-6 d-flex justify-content-between">
                            <span>{{ meal.city }}</span>
                            <span>
                                <a
                                    class="icon-edit me-2"
                                    href="#"

                                    @click.prevent="editResource(meal)"
                                >
                                    <i class="bi bi-pencil-fill"></i>
                                </a>
                                <a
                                    class="icon-edit"
                                    href="#"
                                    @click.prevent="deleteResource(meal)"
                                >
                                    <i class="bi bi-trash"></i>
                                </a>
                            <!--
                                <a
                                    class="icon-edit"
                                    href="#"
                                    @click.prevent="useMeals.deleteResource(meal.id)"
                                >
                                    <i class="bi bi-trash"></i>
                                </a>
                            -->
                            </span>
                        </h5>

                        <div class="card-body">
                            <h5 class="card-title">
                                {{ showDate(new Date(meal.date)) }}
                            </h5>
                            <h6 class="card-subtitle mb-2 text-muted">CustomerID : {{ meal.customer_id }}</h6>
                            <h6 class="card-subtitle mb-2 text-muted">Qty : {{ meal.quantity }}</h6>
                            <h6 class="card-subtitle mb-2 text-muted">GroupID : {{ meal.group_id }}</h6>
                            <p class="card-text fs-6">{{ meal.comment}}</p>

                        </div>
                    </div>
                </template>
            </div>
        </div>



        <MyModal
            v-bind:idProps="state.modal.deleteAlert"
        >
            <!--    Content for header slot     -->
            <template v-slot:header>
                {{ state.modal.header }}
            </template>

            <template v-slot:body>

                <div class="row">
                    <div class="col">
                        <div class="alert alert-warning" role="alert">
                            Delete only one or the entire serie ?
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <button
                            @click="deleteOnlyOne()"
                            class="w-100 mb-2 btn btn-lg rounded-4 btn-info"
                        >Only one</button>
                    </div>
                    <div class="col" v-if="state.resourceToDeleteHasRepetition == true">
                        <button
                            @click="deleteCompleteSerie()"
                            class="w-100 mb-2 btn btn-lg rounded-4 btn-warning"
                        >Complete serie</button>
                    </div>
                </div>
            </template>
        </MyModal>




        <MyModal
            v-bind:idProps="state.modal.edit"
        >
            <!--    Content for header slot     -->
            <template v-slot:header>
                {{ state.modal.header }}
            </template>

            <template v-slot:body>

                <!-- Display server error message from database -->
                <ExceptionMessage
                    v-bind:exception="useMeals.useErrors.state.exception"
                    v-bind:exceptionMessage="useMeals.useErrors.state.exceptionMessage"
                ></ExceptionMessage>


                <div class="form-floating mb-3">
                    <Datepicker
                        v-model="useMeals.state.form.body.date "
                        v-bind:class="formUtilsComp.isFieldEmpty(useMeals.state.form.body.date) ? 'is-invalid' : 'is-valid'"
                        :previewFormat="format"
                        @keyup.enter="saveResource()"
                        :enableTimePicker="false"
                        locale="fr"
                        name="date-picker"
                        required
                    ></Datepicker>
                <!--
                    <label for="date">Date</label>
                -->

                    <!-- Diplay frontend application alert message -->
                    <div v-if="formUtilsComp.isFieldEmpty(useMeals.state.form.body.date)" class="text-info text-sm">Please select a date...</div>
                    <!-- Diplay alert message received from backend -->
                    <ValidationErrors v-bind:errors="useMeals.useErrors.state.errors.date"></ValidationErrors>
                </div>


                <div
                    v-if="testState.form.id === null"
                    class="form-floating mb-3"
                >

                    <input
                        id="customer-empty"
                        type="text"
                        class="form-control rounded-4"
                        v-bind:class="formUtilsComp.isFieldEmpty(testState.form.id) ? 'is-invalid' : 'is-valid'"
                        v-model="testState.form.search"
                        @input="onChange()"
                    />
                    <ul
                        class="list-group"
                        v-show="testState.form.isOpen"
                    >
                        <li
                            class="list-group-item"
                            v-for="(result) in testState.form.customers"
                            :key="result.id"
                            @click="setResult(result)"
                            @mouseover="mouseOver(result.id)"
                            @mouseout="mouseOut()"
                            v-bind:class="isMouseOvering(result.id) ? 'active' : ''"
                        >
                            id: {{ result.id }} -- {{ result.firstname }} {{ result.lastname }}, {{ result.postcode }} {{ result.city }}
                        </li>
                    </ul>

                    <label for="customer-empty">Customer</label>

                    <!-- Diplay frontend application alert message -->
                    <div v-if="formUtilsComp.isFieldEmpty(testState.form.id)" class="text-info text-sm">Please fill up with customer name</div>
                    <!-- Diplay alert message received from backend -->
                    <ValidationErrors v-bind:errors="useMeals.useErrors.state.errors.customer_id"></ValidationErrors>

                </div>


                <div
                    v-else
                    class="form-floating mb-3"
                >
                    <div class="input-group mb-3">
                        <input
                            type="text"
                            class="form-control"
                            v-bind:class="formUtilsComp.isFieldEmpty(testState.form.id) ? 'is-invalid' : 'is-valid'"
                            placeholder="Test field"
                            aria-label="Test field"
                            aria-describedby="button-addon2"
                            v-model="testState.form.search"
                            disabled
                        >
                        <button
                            class="btn btn-outline-secondary"
                            type="button"
                            id="button-addon2"
                            @click="resetSearchedCustomer()"
                        >X</button>
                    </div>
                </div>


                <div class="form-floating mb-3">
                    <input
                        type="number"
                        class="form-control rounded-4"
                        id="quantity"
                        v-bind:class="formUtilsComp.isFieldEmpty(useMeals.state.form.body.quantity) ? 'is-invalid' : 'is-valid'"
                        @keyup.enter="saveResource()"
                        placeholder="quantity"
                        v-model="useMeals.state.form.body.quantity"
                    >
                    <label for="quantity">Quantity</label>
                    <!-- Diplay frontend application alert message -->
                    <div v-if="formUtilsComp.isFieldEmpty(useMeals.state.form.body.quantity)" class="text-info text-sm">Please fill up with your quantity</div>
                    <!-- Diplay alert message received from backend -->
                    <ValidationErrors v-bind:errors="useMeals.useErrors.state.errors.quantity"></ValidationErrors>
                </div>

                <div class="form-floating mb-3">
                    <input
                        type="text"
                        class="form-control rounded-4"
                        id="comment"
                        @keyup.enter="saveResource()"
                        placeholder="comment"
                        v-model="useMeals.state.form.body.comment"
                    >
                    <label for="comment">Comment</label>
                    <!-- Diplay frontend application alert message -->
                    <div v-if="formUtilsComp.isFieldEmpty(useMeals.state.form.body.comment)" class="text-info text-sm">Please fill up with your comment</div>
                    <!-- Diplay alert message received from backend -->
                    <ValidationErrors v-bind:errors="useMeals.useErrors.state.errors.comment"></ValidationErrors>
                </div>

                <div class="form-floating mb-3">
                    <select
                        class="form-select rounded-4"
                        id="repetition"
                        v-model="useMeals.state.form.repetition"
                        @keyup.enter="saveResource()"
                    >
                        <option v-for="option in useMeals.state.form.options.repetition" :value="option.value" v-bind:key="option.value">
                            {{ option.text }}
                        </option>

                    </select>
                    <label for="active">Repetition</label>
                </div>



                <template v-if="useMeals.state.form.repetition >= 1">

                    <div class="form-floating mb-3">
                        <select
                            class="form-select rounded-4"
                            id="endRepetition"
                            v-model="useMeals.state.form.endRepetition"
                            @keyup.enter="saveResource()"
                        >
                            <option v-for="option in useMeals.state.form.options.endRepetition" :value="option.value" v-bind:key="option.value">
                                {{ option.text }}
                            </option>

                        </select>
                        <label for="active">End of repetition</label>
                    </div>


                    <div v-if="useMeals.state.form.endRepetition == 0" class="form-floating mb-3">
                        <input
                            type="number"
                            class="form-control rounded-4"
                            id="occurrences"
                            v-bind:class="formUtilsComp.isFieldEmpty(useMeals.state.form.occurrences) ? 'is-invalid' : 'is-valid'"
                            @keyup.enter="saveResource()"
                            placeholder="occurrences"
                            v-model="useMeals.state.form.occurrences"
                        >
                        <label for="occurrences">Occurrences</label>
                        <!-- Diplay frontend application alert message -->
                        <div v-if="formUtilsComp.isFieldEmpty(useMeals.state.form.occurrences)" class="text-info text-sm">Please fill up with your occurrences</div>
                        <!-- Diplay alert message received from backend -->
                        <ValidationErrors v-bind:errors="useMeals.useErrors.state.errors.occurrences"></ValidationErrors>
                    </div>

                    <div v-if="useMeals.state.form.endRepetition == 1" class="form-floating mb-3">
                        <Datepicker
                            v-model="useMeals.state.form.endDate"
                            :previewFormat="format"
                            @keyup.enter="saveResource()"
                            :enableTimePicker="false"
                            locale="fr"
                            name="date-picker"
                        ></Datepicker>

                        <!-- Diplay frontend application alert message -->
                        <div v-if="formUtilsComp.isFieldEmpty(useMeals.state.form.endDate)" class="text-info text-sm">Please select the end date...</div>
                    </div>

                </template>


                <button
                    @click="saveResource()"
                    class="w-100 mb-2 btn btn-lg rounded-4 btn-primary"
                >Save</button>

            </template>

        </MyModal>


    </div>


</template>


<script>

// import Vue.js framework functions
import { ref } from 'vue';
import { reactive } from 'vue'
//import { computed } from 'vue'
//import { watch } from 'vue'
import { onMounted } from 'vue'

// import external functionalities
import { Modal } from 'bootstrap';

// import application's components
import MyModal from '@/components/MyModal.vue'

// import Datepicker
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css'

// import composables files
import useObjectsUtils from '@/composables/useObjectsUtils.js'
import useFormUtilsComp from '@/composables/useFormUtilsComp.js'
import useDateTimeUtilsComp from '@/composables/useDateTimeUtilsComp.js'
import useMealsComp from '@/composables/useMealsComp.js'
import useCustomersComp from '@/composables/useCustomersComp.js'

// import informations and errors messages components
//import AltertMessage from '@/components/messages/altertMessage.vue'
import ExceptionMessage from '@/components/messages/exceptionMessage.vue'
import ValidationErrors from '@/components/messages/validationErrors.vue'

export default {

    name: 'meals',

    components: {
        Datepicker,
        MyModal,
        //AltertMessage,
        ValidationErrors,
        ExceptionMessage,
    },

    setup() {

        const objUtils = useObjectsUtils()
        const useDateTimeUtils = useDateTimeUtilsComp()
        const formUtilsComp = useFormUtilsComp()
        const useMeals = useMealsComp()
        const useCustomers = useCustomersComp()

        const newDate = ref(new Date())

        const state = reactive({
            date: new Date(),
            modal: {
                edit: 'editMealModal',
                show: 'showMealModal',
                deleteAlert: 'deleteMealAlertModal',
                //alert: 'alertModal',
                header: '',
                action: '',
                componentEdit: null,
                componentShow: null,
                componentDeleteAlert: null,
                componentAlert: null,
            },
            resourceToDelete: null,
            resourceToDeleteHasRepetition: false,

        })


        const testState = reactive({

            form: {
                id: null,
                search: '',
                customers: [],
                isOpen: false,
                mouseOver: '',
            },
            customers: [],
        })


        onMounted(() => {
            getMeals()

            state.modal.componentEdit = new Modal(document.getElementById(state.modal.edit))
            state.modal.componentDeleteAlert = new Modal(document.getElementById(state.modal.deleteAlert))
            //state.modal.componentShow = new Modal(document.getElementById(state.modal.show))
            //state.modal.componentAlert = new Modal(document.getElementById(state.modal.alert))

        })


        function onChange() {

            if (testState.form.search.length >= 2) {

                useCustomers.searchResources(testState.form.search)
                .then((res) =>{
                    //console.log(res)
                    mapCustomers(res)
                })

                testState.form.isOpen = true
            } else {
                testState.form.isOpen = false
            }

        }


        function mapCustomers(dataArray) {

            testState.form.customers = []

            for (var i = 0; i < dataArray.length; i++) {
                let obj = {}
                obj.id = dataArray[i].id
                obj.lastname = dataArray[i].lastname
                obj.firstname = dataArray[i].firstname
                obj.postcode = dataArray[i].postcode
                obj.city = dataArray[i].city
                testState.form.customers.push(obj)
            }
        }


        function setResult(result) {
            testState.form.id = result.id
            testState.form.search = result.firstname + ' ' + result.lastname
            testState.form.isOpen = false
        }


        function resetSearchedCustomer() {
            testState.form.id = null
            testState.form.search = ''
        }


        function mouseOver(id) {
            testState.form.mouseOver = id
        }


        function mouseOut() {
            testState.form.mouseOver = ''
        }


        function isMouseOvering(id) {
            return (testState.form.mouseOver === id) ? true : false
        }


        function getMeals() {

            //console.log(state.date)

            useMeals.getMealsFromDateInterval({
                start: formatIsoDate(useDateTimeUtils.getMonday(state.date)),
                end: formatIsoDate(useDateTimeUtils.getSunday(state.date))
            })
        }


        function deleteResource(resource) {

            state.resourceToDelete = null
            state.resourceToDeleteHasRepetition = false

            // Define modal attributes
            state.modal.header = 'Delete resource'
            state.modal.action = 'delete'

            // Open Modal component
            state.modal.componentDeleteAlert.toggle()

            state.resourceToDelete = resource

            if (resource.group_id !== null) {
                state.resourceToDeleteHasRepetition = true
            } else {
                state.resourceToDeleteHasRepetition = false
            }
        }


        function deleteOnlyOne() {
            useMeals.deleteResource(state.resourceToDelete.id)
            .then(() => {
                state.modal.componentDeleteAlert.toggle()
            })
            .catch((error) => {
                useMeals.useErrors.useSetErrors(error.response.data)
            })
        }


        function deleteCompleteSerie() {
            if ( state.resourceToDelete.group_id !== null ) {
                useMeals.deleteResourcesFromGroupId(state.resourceToDelete)
                .then(() => {
                    state.modal.componentDeleteAlert.toggle()
                })
                .catch((error) => {
                    useMeals.useErrors.useSetErrors(error.response.data)
                })
            }
        }


        function createResource() {

            //testState.customer = ''
            // Reset customer search field
            resetSearchedCustomer()

            // Define modal attributes
            state.modal.header = 'Create new meal'
            state.modal.action = 'create'

            // Open Modal component
            state.modal.componentEdit.toggle()

            // Erase error messages
            useMeals.useErrors.useResetErrors()

            // Flush user's attributes
            useMeals.flushAttributes()
        }


        function editResource(resource) {
            // Define modal attributes
            state.modal.header = 'Edit meal'
            state.modal.action = 'edit'

            // Open Modal component
            state.modal.componentEdit.toggle()

            // Erase error messages
            useMeals.useErrors.useResetErrors()

            // Clone user's attributes
            useMeals.state.form.body = objUtils.cloneObject(resource)
        }


        function saveResource() {

            if ( useMeals.state.form.body.id == null ) {

                useMeals.state.form.body.customer_id = testState.form.id

                useMeals.storeResource({
                    start: formatIsoDate(useDateTimeUtils.getMonday(state.date)),
                    end: formatIsoDate(useDateTimeUtils.getSunday(state.date))
                })
                .then(() => {
                    //console.log('resource created')
                    state.modal.componentEdit.toggle()
                    useMeals.flushAttributes()
                })
                .catch((error) => {
                    useMeals.useErrors.useSetErrors(error.response.data)
                    console.log(error)
                })
            } else {
                useMeals.updateResource()
                .then(() => {
                    console.log('resource updated')
                    // Close Modal component
                    state.modal.componentEdit.toggle()
                })
                .catch((error) => {
                    useMeals.useErrors.useSetErrors(error.response.data)
                })
            }

        }


        // Formated date in YYYY-MM-DD to store in DB
        function formatDate(date) {
            //return date.toISOString().replace(/T.*$/, '')
            //return date.toISOString()

            let day = date.getDate()
            let month = date.getMonth() + 1
            let fullYear = date.getFullYear()
            let dateToShow = fullYear + '-' + month + '-' + day
            return dateToShow
        }

        function formatIsoDate(date) {
            return date.toISOString().replace(/T.*$/, '')
        }

        function showDate(date) {
            let day = date.getDate()
            let month = date.getMonth() + 1
            let fullYear = date.getFullYear()
            let dateToShow = day + '.' + month + '.' + fullYear
            return dateToShow
        }

        function weekDay(date) {
            return date.getDay()
        }

        function nextWeek() {
            state.date = useDateTimeUtils.addOneWeek(state.date)
            getMeals()
        }

        function previousWeek() {
            state.date = useDateTimeUtils.substractOneWeek(state.date)
            getMeals()
        }

        function setToday() {
            state.date = new Date()
            getMeals()
        }

        function updateDate() {
            getMeals()
        }

        // Format data
        const format = (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            return `Selected date is ${day}/${month}/${year}`;
        }


        /**
         * Returned data and functions to the template
         */
        return {
            useMeals,
            mouseOver,
            mouseOut,
            isMouseOvering,
            newDate,
            formatDate,
            createResource,
            useDateTimeUtils,
            weekDay,
            state,
            testState,
            format,
            nextWeek,
            previousWeek,
            setToday,
            showDate,
            formUtilsComp,
            updateDate,
            onChange,
            setResult,
            saveResource,
            editResource,
            deleteResource,
            deleteOnlyOne,
            deleteCompleteSerie,
            resetSearchedCustomer
        }


    }
}

</script>

<style>
@import '../../assets/css/logo.css';

.card {
    font-size: small;
    font-weight: bold;
}

.card .card-header {
    font-size: small;
}

.card .card-body {
    font-weight: small;
    font-size: smaller;
}

/*
.card .card-body a {
    /font-size: large;
    align-items: flex-end;
}
*/

.card .card-title {
    color: red;
}

.card .card-subtitle {
    color: blue;
}

.card .card-text {
    color: green;
}





</style>
